import { Card } from 'flowbite-react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavbarComponent } from '../../../components/navbar/NavbarComponent';
import { CONSTANTS } from '../../../utils/constants';
import { POST_ChangeMemberPassword } from '../../../utils/network/change/member';
import { MemberForgotForm } from './Form';

export const MeForgotView = () => {
  const [searchParams] = useSearchParams(),
    accountType = searchParams.get("accountType"),
    navigate = useNavigate();

    useEffect(() => {
      if (accountType === null) {
        navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME);
      } else {
        if ((accountType !== "1") && (accountType !== "2")) {
          navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME);
        }
        
      }
    }, [accountType, navigate])


  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    POST_ChangeMemberPassword()
  }

  return (
    <>
      <NavbarComponent />
      <div className="w-full min-h-full lg:min-h-[60vh] flex justify-center items-center text-black dark:text-white">
        <div className="px-3 my-40 sm:my-64 w-full sm:w-[35rem] md:w-[35rem] lg:w-[35rem] 2xl:w-[35rem]">
          <h4 className="py-5 flex self-center justify-center text-base font-semibold dark:text-white underline underline-offset-8 uppercase">
            Reset {accountType === "1" ? `Member Account` : `Organization User Account`} Password
          </h4>
          <Card className=''>
            <form onSubmit={handleFormSubmit} method="post" action="#"
              className='w-full' make-general-posts="forgot-password" encType='multipart/form-data'>
              <MemberForgotForm accountType={Number(accountType)} />
            </form>
          </Card>
        </div>
      </div>
    </>
  )
}
