import { Footer } from 'flowbite-react'
import AppTheme from './Theme'
// import Logo from '../../utils/assets/logo/icon.png'
import { CONSTANTS } from '../../utils/constants'
import { useSearchParams } from 'react-router-dom'

export const FooterComponent = () => {
  const [searchParams] = useSearchParams(),
    accountCode = searchParams.get("code"),
    codeParams = accountCode === null ? "" : `?code=${accountCode}`;
  // console.log({ 'searchParams.get("code")': accountCode })
  return (
    <div className='bg-white dark:bg-gray-800 shadow'>
      <Footer container={true}>
        <AppTheme />
        <div className="w-full text-center">
          <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
            {/* <Footer.Brand
              href='#'
              src={Logo}
              alt="Akwaaba Solutions Logo"
              name="Akwaaba Solutions"
            /> */}
            <div></div>
            <Footer.LinkGroup>
              <Footer.Link
                href={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME}`}>
                Home
              </Footer.Link>
              <Footer.Link
                href={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_CLIENT_USER_PASSWORD}`}
                className='px-2'>
                Admin User
              </Footer.Link>
              <Footer.Link
                href={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD}?accountType=1`}
                className='px-2'>
                Member Account
              </Footer.Link>
              <Footer.Link
                href={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD}?accountType=2`}
                className='px-2'>
                Organization User
              </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <Footer.Divider />
          {/* <Footer.Copyright
            by="Akwaaba Solutions"
            year={new Date().getFullYear()}
          /> */}
          <div>
            <span
              className="text-sm text-gray-500 dark:text-gray-400 sm:text-center">
              <a href="https://akwaabasolutions.com/terms-and-conditions-2/" className="ml-1 hover:underline">
                Terms & Conditions
              </a>
            </span>
          </div>
        </div>
      </Footer>
    </div>
  )
}
