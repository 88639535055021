import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CONSTANTS } from '../../../constants';

export async function POST_ResetMemberPassword() {
  const formContainer: HTMLFormElement | null = document.querySelector('[make-general-posts="reset-password"]'),
    formData = new FormData(formContainer!);
  // console.log({ formData, formContainer });
  const axiosConfig = {
    method: 'post',
    url: CONSTANTS.URL_CONSTANTS.API_BASE_URL + 'members/password/reset',
    headers: {
      // 'content-Type': 'application/json',
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expired": "0",
      "Cookie": document.cookie
    },
    data: formData
  };

  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: "<p>Submit Form?</p>",
    icon: "question",
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: "Cancel",
  }).then(async (e) => {
    if (e.isConfirmed) {
      return await axios(axiosConfig).then((response) => {
        const DATA = response.data;
        // console.log({ "DATA": DATA });

        const MySwal3 = withReactContent(Swal)

        MySwal3.fire({
          title: DATA.msg,
          icon: "success",
        }).then(() => {
          window.location.href = CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME;
        });
        return DATA;
      }).catch((err) => {
        // console.log({ "POST_IndividualUserRegistration-err": err, "err.response": err.response });
        const data = err.response.data;
        let infos = `<style>#swal_error_content br {display: none !important;}</style><div id="swal_error_content">`;
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element: Array<string> = data[key];
            const infoLi = element.map(li => {
              return `<li class="text-sm">${li}</li>`;
            }).toString()
            let info = `<div>
            <h4 class="whitespace-nowrap text-lg font-semibold uppercase">${key}</h4>
            <ul> ${infoLi} </ul>
          </div>`;
            infos += info;
          }
        }
        infos += "</div>"

        const MySwal2 = withReactContent(Swal)

        MySwal2.fire({
          title: infos,
          icon: "error",
        });
        throw err.response.data;

      });
    }
  });
}