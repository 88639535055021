import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FooterComponent } from './components/footer/FooterComponent';
import { CONSTANTS } from './utils/constants';
import { CuForgotView } from './views/client-user/forgot/CuForgotView';
import { CuResetView } from './views/client-user/reset/CuResetView';
import { HomeView } from './views/home/HomeView';
import { MeForgotView } from './views/member/forgot/MeForgotView';
import { MeResetView } from './views/member/reset/MeResetView';

function App() {
  return (
    <div className="App bg-gray-100 shadow dark:bg-gray-900">
      <Router>
        <Routes>
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME} element={<HomeView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.RESET_CLIENT_USER_PASSWORD} element={<CuResetView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_CLIENT_USER_PASSWORD} element={<CuForgotView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.RESET_MEMBER_PASSWORD} element={<MeResetView />} />
          <Route path={CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD} element={<MeForgotView />} />
        </Routes>
        <FooterComponent />
      </Router>
    </div>
  );
}

export default App;
