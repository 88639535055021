import { Card } from 'flowbite-react';
import { FaShieldAlt } from 'react-icons/fa';
import { CONSTANTS } from '../../utils/constants';
import { Link } from 'react-router-dom';

export const HomeView = () => {

  return (
    <div className='py-[20vh] md:py-[30vh] text-black dark:text-white p-3'>
      <div className='flex justify-center'>
        <div className="h-0 md:h-10"></div>
      </div>
      <div className='text-sm w-full'>
        <div className="flex justify-center flex-col mx-auto">
          <div className='w-full md:w-[55rem] mx-auto' >
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 pb-5'>
              <div className='p-2'>
                <Card>
                  <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                    Admin User
                  </h5>
                  <p className="mb-4 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
                    Reset Password Here.
                  </p>
                  <div className="items-end justify-end space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_CLIENT_USER_PASSWORD}
                      className="inline-flex w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2.5 text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 sm:w-auto">
                      <FaShieldAlt className='mr-2' />
                      <div className="text-left">
                        <div className="mb-1 text-xs">
                          Reset
                        </div>
                        <div className="-mt-1 font-sans text-sm font-semibold">
                          Password
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card>
              </div>
              <div className='p-2'>
                <Card>
                  <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                    Member Account
                  </h5>
                  <p className="mb-4 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
                    Reset Password Here.
                  </p>
                  <div className="items-end justify-end space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD}?accountType=1`}
                      className="inline-flex w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2.5 text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 sm:w-auto">
                      <FaShieldAlt className='mr-2' />
                      <div className="text-left">
                        <div className="mb-1 text-xs">
                          Reset
                        </div>
                        <div className="-mt-1 font-sans text-sm font-semibold">
                          Password
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card>
              </div>
              <div className='p-2'>
                <Card>
                  <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                    Organization User Account
                  </h5>
                  <p className="mb-4 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
                    Reset Password Here.
                  </p>
                  <div className="items-end justify-end space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <Link
                      to={`${CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD}?accountType=2`}
                      className="inline-flex w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2.5 text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 sm:w-auto">
                      <FaShieldAlt className='mr-2' />
                      <div className="text-left">
                        <div className="mb-1 text-xs">
                          Reset
                        </div>
                        <div className="-mt-1 font-sans text-sm font-semibold">
                          Password
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
