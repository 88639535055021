import { Button, Label, TextInput } from 'flowbite-react'
import { useEffect } from 'react';
import { CheckFormErrorBeforeSubmit } from '../../../utils/functions/check_form_error'
import { togglePswdVisibility } from '../../../utils/functions/toggle_pswd_visibility';

export const MemberResetForm = (prop: { context: string }) => {
  const context = prop.context;

  useEffect(() => {
    togglePswdVisibility();
  }, [])

  return (
    <>
      <div className='grid grid-cols-1 grid-flow-row gap-4 pb-5'>
        <input type="hidden" name="code" value={context} />
        <div className='p-2'>
          <div className="mb-2 block">
            <Label
              htmlFor="password"
              value="Enter password*" />
          </div>
          <TextInput
            id="password"
            name='password'
            type="password"
            placeholder="Password*"
            required={true} />
        </div>
        <div className='p-2'>
          <div className="mb-2 block">
            <Label
              htmlFor="password-confirm"
              value="Enter password confirmation*" />
          </div>
          <TextInput
            id="password-confirm"
            name='password-confirm'
            type="password"
            placeholder="Password Confirmation*"
            required={true} />
        </div>
      </div>
      <div className='flex justify-center mt-2'>
        <Button type="submit" color={"warning"}
          onClick={(e) =>
            CheckFormErrorBeforeSubmit(e, '[make-general-posts="reset-password"]')
          }>
          <div className='w-full max-w-full'>Submit</div>
        </Button>
      </div>
    </>
  )
}
