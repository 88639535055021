import { Button, Navbar } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../utils/constants';

export const NavbarComponent = () => {
  // const _organizationValue = properties.organization,
  const navigate = useNavigate();
  // console.log({ 'searchParams.get("code")': accountCode })

  const goToHome = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME);
  }

  const goToClientUserForm = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_CLIENT_USER_PASSWORD);
  }

  const goToMemberAccountForm = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD + "?accountType=1");
  }

  const goToOrganizationMemberForm = () => {
    navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.FORGOTTEN_MEMBER_PASSWORD + "?accountType=2");
  }

  return (
    <>
      <div className='bg-white dark:bg-gray-800 shadow'>
        <Navbar
          fluid={true}
          rounded={true} >
          {/* <Navbar.Brand>
          <img
            src={clientCode?.clientInfo?.logo}
            className="mr-3 h-14"
            alt={clientCode?.clientInfo?.name + ` : Logo`}
          />
          <span className="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
            {clientCode?.clientInfo?.name}
          </span>
        </Navbar.Brand> */}
          <div></div>
          <div className="flex md:order-2">
            <span className='px-2'>
              <Button onClick={goToHome}>
                Home
              </Button>
            </span>
            <Navbar.Toggle />
          </div>
          <Navbar.Collapse>
            <Navbar.Link onClick={goToClientUserForm}
              active={true} >
              Admin User
            </Navbar.Link>
          </Navbar.Collapse>
          <Navbar.Collapse>
            <Navbar.Link onClick={goToMemberAccountForm}
              active={true} >
              Member Account
            </Navbar.Link>
          </Navbar.Collapse>
          <Navbar.Collapse>
            <Navbar.Link onClick={goToOrganizationMemberForm}
              active={true} >
              Organization User
            </Navbar.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
}
