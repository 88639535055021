import { Card } from 'flowbite-react';
import { NavbarComponent } from '../../../components/navbar/NavbarComponent';
import { POST_ChangeClientPassword } from '../../../utils/network/change/client';
import { ClientForgotForm } from './Form';

export const CuForgotView = () => {
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    POST_ChangeClientPassword()
  }

  return (
    <>
      <NavbarComponent />
      <div className="w-full min-h-full lg:min-h-[60vh] flex justify-center items-center text-black dark:text-white">
        <div className="px-3 my-40 sm:my-64 w-full sm:w-[35rem] md:w-[35rem] lg:w-[35rem] 2xl:w-[35rem]">
          <h4 className="py-5 flex self-center justify-center whitespace-nowrap text-base font-semibold dark:text-white underline underline-offset-8 uppercase">
            Reset Admin User Password
          </h4>
          <Card className=''>
            <form onSubmit={handleFormSubmit} method="post" action="#"
              className='w-full' make-general-posts="forgot-password" encType='multipart/form-data'>
              <ClientForgotForm />
            </form>
          </Card>
        </div>
      </div>
    </>
  )
}
