import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NavbarComponent } from "../../../components/navbar/NavbarComponent";
import { CONSTANTS } from "../../../utils/constants";
import { ClientResetForm } from "./Form";
import { Buffer } from "buffer";
import { POST_ResetClientPassword } from "../../../utils/network/reset/client";

export const CuResetView = () => {
  const [searchParams] = useSearchParams(),
    _context = searchParams.get("context"), navigate = useNavigate();

  const [context, setcontext] = useState<{ id: Number, email: String, firstname: String, lastname: String, accountType: Number }>(),
    [contextSet, setcontextSet] = useState<boolean>(false)

  useEffect(() => {
    if (_context === null) {
      navigate(CONSTANTS.URL_CONSTANTS.PAGE_URLS.HOME);
    } else {
      if (contextSet === false) {
        // console.log({"context-0": context});
        setcontext(JSON.parse(Buffer.from(_context, 'base64').toString('utf-8')));
        setcontextSet(true);
        // console.log({"context-1": context});
      }
      
    }
  }, [_context, context, contextSet, navigate])


  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    POST_ResetClientPassword()
  }
  // console.log({context});

  return (
    <>
      <NavbarComponent />
      <div className="w-full min-h-full lg:min-h-[60vh] flex justify-center items-center text-black dark:text-white">
        <div className="px-3 mt-20 mb-20 sm:mt-32 sm:mb-40 w-full sm:w-[35rem] md:w-[35rem] lg:w-[35rem] 2xl:w-[35rem]">
          <h4 className="py-5 flex self-center justify-center text-base font-semibold dark:text-white underline underline-offset-8 uppercase">
          Reset Admin User Password
          </h4>
          <Card className=''>
            <form onSubmit={handleFormSubmit} method="post" action="#"
              className='w-full' make-general-posts="reset-password" encType='multipart/form-data'>
              <h6 className="py-5 flex self-center justify-center text-center text-sm font-semibold dark:text-white uppercase">
                Hello {context?.firstname} {context?.lastname}, <br/>Enter New Password
              </h6>
              <ClientResetForm context={String(_context)} />
            </form>
          </Card>
        </div>
      </div>
    </>
  )
}
