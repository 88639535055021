import { PRODUCTION_MODE } from "../env";

export const URL_CONSTANTS = {
  API_BASE_URL: PRODUCTION_MODE ?
    "https://db-api-v2.akwaabasoftware.com/" :
    "http://127.0.0.1:9001/api/",
  PAGE_URLS: {
    HOME: "/",
    RESET_CLIENT_USER_PASSWORD: "/client-user/reset-password",
    RESET_MEMBER_PASSWORD: "/member/reset-password",
    FORGOTTEN_CLIENT_USER_PASSWORD: "/client-user/forgot-password",
    FORGOTTEN_MEMBER_PASSWORD: "/member/forgot-password"
  }
};