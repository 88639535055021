import { Button, Label, TextInput } from 'flowbite-react'
import { CheckFormErrorBeforeSubmit } from '../../../utils/functions/check_form_error'

export const ClientForgotForm = () => {
  return (
    <>
      <div className='grid grid-cols-1 grid-flow-row gap-4 pb-5'>
        <div className='p-2'>
          <div className="mb-2 block">
            <Label
              htmlFor="email"
              value="Enter Email Address*" />
          </div>
          <TextInput
            id="email"
            name='email'
            type="email"
            placeholder="Enter Email Address*"
            required={true} />
        </div>
      </div>
      <div className='flex justify-center mt-2'>
        <Button type="submit" color={"warning"}
          onClick={(e) =>
            CheckFormErrorBeforeSubmit(e, '[make-general-posts="forgot-password"]')
          }>
          <div className='w-full max-w-full'>Submit</div>
        </Button>
      </div>
    </>
  )
}
